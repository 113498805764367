import * as React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home';
import GoogleLoginComponent from './components/GoogleLogin';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<GoogleLoginComponent />} />
        <Route path="/home" element={<Home />} />
      </Routes>
    </Router>
  );
}

export default App;
