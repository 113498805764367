import * as React from 'react';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Button, Card, Layout, Menu, Typography, notification,
} from 'antd';
import { BankOutlined, DashboardOutlined, UserOutlined } from '@ant-design/icons';
import axios from 'axios';

const { Header, Sider, Content } = Layout;
const { Title } = Typography;

function Home() {
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [, setAuthCode] = useState<string | null>(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const code = query.get('code');
    const state = query.get('state');
    const storedStateToken = localStorage.getItem('state_token');

    if (code && state === storedStateToken) {
      localStorage.removeItem('state_token');
      localStorage.setItem('monzo_auth', 'true');
      setAuthCode(code);
    } else if (state && state !== storedStateToken) {
      console.error('Invalid state token');
    }
  }, [location]);

  const handleButtonClick = async () => {
    const token = localStorage.getItem('google_token');
    if (!token) {
      notification.error({
        message: 'Error',
        description: 'No token found in localStorage',
      });
      return;
    }

    try {
      const response = await axios.post('https://api.moola.dev/sheets', {
        token,
        sheet_name: 'testing',
      });
      notification.success({
        message: 'Success',
        description: `Response: ${response.data}`,
      });
    } catch (error) {
      notification.error({
        message: 'Error',
        description: `An error occurred: ${error}`,
      });
    }
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider collapsible collapsed={collapsed} onCollapse={setCollapsed}>
        <div className="logo" style={{ color: 'white', textAlign: 'center', padding: '16px 0' }}>
          <Title level={3} style={{ color: 'white', margin: 0 }}>moola</Title>
        </div>
        <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline">
          <Menu.Item key="1" icon={<DashboardOutlined />}>
            Dashboard
          </Menu.Item>
          <Menu.Item key="2" icon={<UserOutlined />} onClick={() => navigate('/user-profile')}>
            User Profile
          </Menu.Item>
          <Menu.Item key="3" icon={<BankOutlined />}>
            Monzo Login
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout>
        <Header style={{ background: '#fff', padding: 0 }}>
          <Title level={2} style={{ margin: '16px' }}>Dashboard</Title>
        </Header>
        <Content style={{ margin: '16px' }}>
          <Card title="Welcome to the Dashboard" bordered={false} style={{ marginBottom: '16px' }}>
            <p>This is your main dashboard content.</p>
            <Button type="primary" onClick={handleButtonClick}>
              Make me a sheet
            </Button>
          </Card>
        </Content>
      </Layout>
    </Layout>
  );
}

export default Home;
