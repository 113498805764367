import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useGoogleLogin, TokenResponse } from '@react-oauth/google';
import {
  Button, Typography, Row, Col,
} from 'antd';
import { GoogleOutlined } from '@ant-design/icons';

const { Title } = Typography;

function GoogleLoginComponent() {
  const navigate = useNavigate();

  const login = useGoogleLogin({
    scope: [
      'openid',
      'https://www.googleapis.com/auth/drive',
      'https://www.googleapis.com/auth/drive.file',
      'https://www.googleapis.com/auth/drive.readonly',
      'https://www.googleapis.com/auth/spreadsheets',
      'https://www.googleapis.com/auth/spreadsheets.readonly',
    ].join(' '),
    onSuccess: (tokenResponse: TokenResponse) => {
      console.log('Login Success: tokenResponse:', tokenResponse);
      localStorage.setItem('google_token', JSON.stringify(tokenResponse));
      navigate('/home');
    },
    onError: (error) => {
      console.log('Login Failed:', error);
    },
  });

  const handleLoginClick = () => {
    login();
  };

  return (
    <div style={{
      backgroundColor: '#f0f2f5', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center',
    }}
    >
      <Row justify="center" align="middle" style={{ textAlign: 'center' }}>
        <Col>
          <Title level={1} style={{ color: '#1890ff' }}>moola</Title>
          <Title level={2}>Login with Google</Title>
          <Button
            type="primary"
            icon={<GoogleOutlined />}
            onClick={handleLoginClick}
            style={{ backgroundColor: '#4285F4', borderColor: '#4285F4' }}
          >
            Login with Google
          </Button>
        </Col>
      </Row>
    </div>
  );
}

export default GoogleLoginComponent;
